import finesApi from '@rosfines/vue-common/common/api/finesApi'
import config from '@/config'

let _eventsHistory = []
// TODO: переписать на использование класса вместо хранения истории в переменной вне компонентаexport default
let featuresLoaderResolve
const featuresLoader = new Promise(resolve => {
	featuresLoaderResolve = resolve
})

export default {
	featuresLoader,
	featuresLoaderResolve,
	featuresList: [],

	async trackEvent(event, params = {}, ymEnabled = false) {
		try {
			const { userId, session } = finesApi.getRequestParams()
			await this.featuresLoader

			const data = {
				userId,
				event,
				params,
				session,
				channel: 'WebVersion',
				features: this.featuresList.join(', '),
			}
			if (!data.params.history) {
				data.params.history = _eventsHistory.slice(0, 5).map((item, index) => {
					const res = {}
					res[index + 1] = item
					return res
				})
			}
			_eventsHistory.push(event)
			if (_eventsHistory.length > 5) {
				_eventsHistory = _eventsHistory.slice(_eventsHistory.length - 5)
			}

			if (
				process.env.NODE_ENV !== 'production' ||
				localStorage.getItem('debugMode')
			) {
				console.log(
					`%c${event} - ${data?.params?.action}`,
					'color: white; background: #448aff; padding: 2px',
					data
				)
			}

			if (ymEnabled && window.ym) {
				window.ym(config.YM_SITEKEY, 'reachGoal', event)
			}

			return await finesApi.post(`/userAction/tracking`, JSON.stringify(data))
		} catch (error) {
			console.error(error)
		}
		return false
	},
}
